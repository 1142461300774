import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import * as Styled from './styles/SubPageHero.styles'
import { RegionContainer } from '../styles/Utility.styles'

const SubPageHero = ({ heroText }) => (
  <RegionContainer>
    <Styled.HeroText>
      <PrismicRichText field={heroText.richText} />
    </Styled.HeroText>
  </RegionContainer>
)

export default SubPageHero
